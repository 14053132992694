import { Component, HostListener, OnInit } from '@angular/core';
import { PlannersService } from '../../planners/services/planners.service';
import { IdeaAndTipsService } from '../services/ideas-and-tips.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Config } from '../../../config';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-wedding-ideas-details',
  templateUrl: './wedding-ideas-details.component.html',
  styleUrls: ['./wedding-ideas-details.component.scss']
})
export class WeddingIdeasDetailsComponent implements OnInit {
  activeUser: any;
  post_slug: string;
  post_details: any;
  posts: any[] = [];
  displayedPosts: any[] = [];
  articlesPerPage = 5; // Set the number of articles to load per request
  currentPage = 0;
  ideaAndTipsPhotoUrl = Config.imageUrl + 'images/idea_and_tips/';

  constructor(
    private plannersService: PlannersService,
    public route: ActivatedRoute,
    private ideaAndTipsService: IdeaAndTipsService,
    private sanitizer: DomSanitizer,
  ) {
    this.plannersService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
      } else {
        this.activeUser = null;
      }
    }, (err) => {
        console.error(err);
    });
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params) {
        if (params['slug']) {
          this.post_slug = params['slug'];
          this.getPostDetails(this.post_slug);
        }
      }
    });
  }

  async getPostDetails(slug: string){
    let formData = {
      "slug": slug
    };
    var response = await this.ideaAndTipsService.getIdeaAndTipsArticleDetails(formData).toPromise();
    this.post_details = response.data;
    this.getIdeaAndTips({
      'cat_id': this.post_details.cat_details.id
    });
  }

  getRichTextContent(content: any) {
    let el = document.createElement("div");
    el.innerHTML = content;
    let tempcontent = el.outerHTML;
    return this.sanitizer.bypassSecurityTrustHtml(tempcontent);
  }

  async getIdeaAndTips(formData: any){
    var response = await this.ideaAndTipsService.getIdeaAndTips(formData).toPromise();
    this.posts = response.data.filter(p => p.slug !== this.post_slug);
    this.loadMoreArticles();
  }

  loadMoreArticles(): void {
    if (this.currentPage < this.articlesPerPage) {
      this.displayedPosts.push(this.posts[this.currentPage]);
      this.currentPage++;
    }
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: Event): void {
    const element = event.target as HTMLElement;
    if (element.scrollTop + element.clientHeight >= element.scrollHeight - 100) {
      this.loadMoreArticles(); // Load more articles when scrolling close to the bottom
    }
  }
}
