import { Component, OnInit } from '@angular/core';
import { IdeaAndTipsService } from '../services/ideas-and-tips.service';
import { Router } from '@angular/router';
import { PlannersService } from '../../planners/services/planners.service';
import { Config } from '../../../config';
declare var $: any;

@Component({
  selector: 'app-wedding-ideas',
  templateUrl: './wedding-ideas.component.html',
  styleUrls: ['./wedding-ideas.component.scss']
})
export class WeddingIdeasComponent implements OnInit {
  search_text: string = "";
  search_dropdown_visible: boolean = false;
  search_result: any[] = [];
  ideaAndTipsCategories: any;
  ideaAndTips: any;
  activeUser: any;
  ideaAndTipsPhotoUrl = Config.imageUrl + 'images/idea_and_tips/';

  constructor(
    private ideaAndTipsService: IdeaAndTipsService,
    public router: Router,
    private plannersService: PlannersService,
  ) { 
    this.plannersService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
      } else {
        this.activeUser = null;
      }
    }, (err) => {
        console.error(err);
    });
  }

  async ngOnInit() {
    await this.getIdeaAndTipsCategories();
    await this.getIdeaAndTips();
    var owl = $('.owl-carousel');
    setTimeout(() => {
      owl.owlCarousel({
        margin: 30,
        nav: true,
        loop:true,
        autoplay:false,
        autoplayTimeout:3000,
        autoplayHoverPause:true,
        smartSpeed: 400,
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 4
          },
          1000: {
            items: 4
          }
        }
      })
    }, 1000); 
  }
  async getIdeaAndTipsCategories() {
    var categories = await this.ideaAndTipsService.getIdeaAndTipsCategories().toPromise();
    this.ideaAndTipsCategories = categories.data;
  }

  async getIdeaAndTips() {
    var lists = await this.ideaAndTipsService.getIdeaAndTipsList().toPromise();
    this.ideaAndTips = lists.data;
  }

  openDropdown(){
    this.search_dropdown_visible = true;
  }
  hideDropdown(){
    setTimeout(()=>{
      this.search_dropdown_visible = false;
    }, 300);
  }
  async search_ideas_and_tips(){
    if(this.search_text.length < 3){
      return;
    }
    let formData = {
      "search_text": this.search_text
    };
    var response = await this.ideaAndTipsService.searchIdeasAndTips(formData).toPromise();
    if(response.hasError === false){
      this.search_result = response.data;
    }
  }
  async goToIdeaAndTipDetailsPage(slug: any) {
    this.router.navigate(['/ideas-and-tips/' + slug]);
  }
  goToCategory(cat: any){
    this.router.navigate(['/ideas-and-tips/' + cat.slug]);
  }

  columns(){
    return [
      this.ideaAndTipsCategories.slice(0, Math.ceil(this.ideaAndTipsCategories.length / 3)),
      this.ideaAndTipsCategories.slice(Math.ceil(this.ideaAndTipsCategories.length / 3), 2 * Math.ceil(this.ideaAndTipsCategories.length / 3)),
      this.ideaAndTipsCategories.slice(2 * Math.ceil(this.ideaAndTipsCategories.length / 3))
    ];  
  }
}
