import { Component, OnInit } from '@angular/core';
import { LocationsService } from '../../locations/services/locations.service';
import { RegionModel } from 'src/app/models/region.model';
import { ToastrService } from 'ngx-toastr';
import { LocationModel } from 'src/app/models/location.model';
import { PlannerProfileFormBuilder } from '../../planners/planner-dashboard/planner.profile.form-builder';
import { PlannerProfileModel } from 'src/app/models/planner.profile.model';
import { PlannersService } from '../services/planners.service';
import { Config } from '../../../config';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ChecklistService } from '../services/checklist.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GuestlistService } from '../services/guestlist.service';
import { SeatingChartService } from '../services/seating-chart.service';
import { BudgetlistService } from '../services/budgetlist.service';
import { DiscussionService } from '../services/discussions.service';
import { VendorManagerService } from '../services/vendor-manager.service';

declare var $: any;
@Component({
  selector: 'app-planner-dashboard',
  templateUrl: './planner-dashboard.component.html',
  styleUrls: ['./planner-dashboard.component.css']
})
export class PlannerDashboardComponent implements OnInit {
  websiteContentData: any[] = [];
  websiteUrl = location.origin

  public min = new Date();
  timeData = [];
  minData = [];
  cities: any;
  regions: any;
  submitted = false;
  model = new PlannerProfileModel();
  locationModel = new LocationModel();
  plannerProfileForm: any;
  profilePhoto: any;
  event_id: any;
  profileData: any;
  linkEmail: any;
  showLink: boolean = true;
  weddings: any;
  loginUserId: number;
  first_couple_id: number;
  second_couple_id: number;
  eventdate: any;
  first_couple_name: any;
  second_couple_name: any;
  wedding_website_url_name:any;
  countdownText: any;
  funsettimeout: any;
  accountLink: any;

  profileImage: any;

  imageChangedEvent: any = '';
  croppedImage: any = '';
  showCropper: boolean;
  public modalElement: HTMLElement;
  fileName: any;
  fileType: any;
  showTham: boolean;

  first: any;
  second: any;
  name: string;

  p: number = 0;
  t: number = 10000;
  countCheckLists: any;
  hiredVendorData: any;
  activeUser: any;
  allChecklist: any;

  public AddLinkForm: FormGroup;
  linksubmitted: boolean=false;
  emailRegEx = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
  allEvent: any;
  modifyGuest: any[];
  apiGuestList: any;
  allGuest: unknown[];
  EventGuestList: any;
  allEventData: any;
  filterEvent: any;
  eventId: any;
  tableGuest: any = 0;
  attendGuest: any = 0;
  totalGuest: any = 0;
  totalBudgetAmount: any;
  weddingBudgetCost: any;
  summary: number;
  overAllSummary: number;
  overAll: boolean;
  noteHide: boolean = true;
  website_Url: any;
  public_conversations: any[] = [];
  perImage = [
    { min: 0, max: 5, image: 'champagne-status-glass-percentage-0-to-5.png' },
    { min: 6, max: 10, image: 'champagne-status-glass-percentage-06-to-10.png' },
    { min: 11, max: 15, image: 'champagne-status-glass-percentage-11-to-15.png' },
    { min: 16, max: 20, image: 'champagne-status-glass-percentage-16-to-20.png' },
    { min: 21, max: 25, image: 'champagne-status-glass-percentage-21-to-25.png' },
    { min: 26, max: 30, image: 'champagne-status-glass-percentage-26-to-30.png' },
    { min: 31, max: 35, image: 'champagne-status-glass-percentage-31-to-35.png' },
    { min: 36, max: 40, image: 'champagne-status-glass-percentage-36-to-40.png' },
    { min: 41, max: 45, image: 'champagne-status-glass-percentage-41-to-45.png' },
    { min: 46, max: 50, image: 'champagne-status-glass-percentage-46-to-50.png' },
    { min: 51, max: 55, image: 'champagne-status-glass-percentage-51-to-55.png' },
    { min: 56, max: 60, image: 'champagne-status-glass-percentage-56-to-60.png' },
    { min: 61, max: 65, image: 'champagne-status-glass-percentage-61-to-65.png' },
    { min: 66, max: 70, image: 'champagne-status-glass-percentage-66-to-70.png' },
    { min: 71, max: 75, image: 'champagne-status-glass-percentage-71-to-75.png' },
    { min: 76, max: 80, image: 'champagne-status-glass-percentage-76-to-80.png' },
    { min: 81, max: 85, image: 'champagne-status-glass-percentage-81-to-85.png' },
    { min: 86, max: 90, image: 'champagne-status-glass-percentage-86-to-90.png' },
    { min: 91, max: 95, image: 'champagne-status-glass-percentage-91-to-95.png' },
    { min: 96, max: 100, image: 'champagne-status-glass-percentage-96-to-100.png' },
  ];

  constructor(
    private locationService: LocationsService,
    private toastr: ToastrService,
    private router: Router,
    private formBuilder: PlannerProfileFormBuilder,
    private plannersService: PlannersService, private checklistService: ChecklistService,
    private guestlistService: GuestlistService,
    private VendorManagerService: VendorManagerService,
    private seatingChartService: SeatingChartService,
    public formBuilder1: FormBuilder,
    private budgetListService: BudgetlistService,
    private discussionService: DiscussionService,
  ) {
    this.plannersService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
        this.getEventList(this.activeUser);
      } else {
        this.activeUser = null;
      }
    },
      (err) => {
        console.error(err);
      });
    this.AddLinkForm = this.formBuilder1.group({
      linkEmail: ['', Validators.compose([Validators.required, Validators.pattern(this.emailRegEx)])]
    });
  }

  getWebsiteTextContent(content_slug:string){
    let text_content = "";
    for(let i=0; i<this.websiteContentData.length; i++){
      if(this.websiteContentData[i].content_slug === content_slug){
        text_content = this.websiteContentData[i].text_content;
        break;
      }
    }
    return text_content;
  }

  async ngOnInit(){
    $('#basicExampleModal').on('hidden.bs.modal', () => {
      this.AddLinkForm.reset();
      this.accountLink = null;
      this.linkEmail = null;
    });

    window.scroll(0, 0);
    var imageUrl = Config.imageUrl;
    this.getWeddings();
    this.loginUserId = Number(this.plannersService.getCurrentUid());
    this.plannersService.assignWedding.subscribe((result: any) => {    
    this.weddings = result;
    this.myProfileData();
      // this.plannerProfileForm.get('event_date').setValue(new Date());
    }, error =>{
      this.toastr.error(error.message);
    });

    this.plannerProfileForm = this.formBuilder.getForm();    
    for (var i = 0; i < 24; i++) {
      var ii: any;
      var kk: any;
      if (i < 10) {
        ii = "0" + i;
      } else {
        ii = i;
      }
      this.timeData.push(ii);
    }
    for (var k = 0; k < 60; k++) {
      if (k < 10) {
        kk = "0" + k;
      } else {
        kk = k;
      }
      this.minData.push(kk);
    }


    this.locationService.getRegions("")
      .subscribe(
        (response: RegionModel[]) => {
          for (var i = 0; i < response.length; i++) {
            response[i]['text'] = response[i].name;
          }
          this.regions = response;
        },
        error => {
          this.toastr.error(error.message);
        }
      )
    await this.getChecklist();
    await this.getGuestList(this.activeUser.id);
    await this.getHiredVendorDetails(this.activeUser.id);
    await this.getEventGuestList();
    await this.totalBudgetCost();

    let public_conversations = await this.discussionService.getPublicLatestDiscussion().toPromise();
    this.public_conversations = public_conversations.data;
  }

  get k() {
    return this.AddLinkForm.controls;
  }

  async getChecklist() {
    this.plannersService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
        this.countCheckList();
        this.filterCheckList();
      } else {
        this.activeUser = null;
      }

    },
      (err) => {
        console.error(err);
      });
  }


  async getEventList(weddingId: any) {
    let data = {
      wedding_id: weddingId
    };
    var eventList = await this.guestlistService.getEventList(data).toPromise();
    this.allEvent = eventList.data;
    this.allEventData = eventList.data;
    if(this.allEventData && this.allEventData.length){
      this.filterEvent = this.allEventData.filter((e) => e.is_seating_chart);
      this.eventId = this.filterEvent[0].id
      return eventList.data;
    }else{
      return [];
    }    
  }
  async getGuestList(weddingId: any) {
    let data = {
      wedding_id: weddingId,
      search_value: '',
      page_size: 10,
      start_index: 0
    };
    var guestList = await this.guestlistService.getGuestList(data).toPromise();
    this.apiGuestList = guestList.data;    
    this.apiGuestList = this.apiGuestList.filter((e)=> e.relationship_name != "Couple")
    var grouped = this.groupBy(this.apiGuestList, false, (relation: { relationship_name: any; }) => relation.relationship_name);
    this.allGuest = this.mapEntries(grouped);
    for (let index = 0; index < this.apiGuestList.length; index++) {
     for (let j = 0; j < this.apiGuestList[index].event.length; j++) {
      if(this.apiGuestList[index].event[j].attendace == 'Attending' || this.apiGuestList[index].event[j].attendace == 'Cancelled'){
        this.attendGuest++ ;
        break ;
      }
     }
    }
    for (let index = 0; index < this.apiGuestList.length; index++) {
      for (let j = 0; j < this.apiGuestList[index].event.length; j++) {
       if(this.apiGuestList[index].event[j].attendace != 'Cancelled'){
         this.totalGuest++ ;
         break ;
       }
      }
     }
    console.log(this.apiGuestList);    
  }

  async getHiredVendorDetails(weddingId: any) {
    var count = await this.VendorManagerService.getVendorCategorysCount(weddingId).toPromise();
    this.hiredVendorData = {
      hired: count.total_vendors_hired_by_user,
      total: count.total_category_enabled_by_user
    }
  }
  mapEntries(grouped: any) { return Array.from(grouped.entries()); }

  groupBy(list: any, checked: boolean, keyGetter: any) {
    const map = new Map();
    var temp = [];
    list.forEach((item: any) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      const data = {
        item: item,
        showEventAttend: null,
        isChecked: checked

      };
      temp.push(data);
      if (!collection) {
        map.set(key, [data]);
      } else {
        collection.push(data);
      }
    });
    this.modifyGuest = temp;
    return map;
  }
  

  async getEventGuestList() {
    let guestData = {
      wedding_id: this.activeUser.id,
      event_id: this.eventId,
    }
    this.seatingChartService.getEventGuestList(guestData).subscribe(res => {
      this.EventGuestList = res.data;
      for (let index = 0; index < this.EventGuestList.length; index++) {
       if(this.EventGuestList[index].table_id){
        this.tableGuest++
       }              
      }
    }, error => {
      this.toastr.error(error.message);
    })
  }

  async totalBudgetCost() {
    var cost = await this.budgetListService.geBudgetCost(this.activeUser.id).toPromise();
    this.totalBudgetAmount = cost;    
    this.weddingBudgetCost = this.totalBudgetAmount.total_budget_cost.toFixed(2);
    this.summary = this.totalBudgetAmount.total_budget_cost - this.totalBudgetAmount.total_actual_cost;
    if (this.summary > 0 || this.summary == 0) {
      this.overAll = true;
    } else {
      this.overAll = false;
    }
    this.overAllSummary = Math.abs(this.summary);
  }
  async countCheckList() {
    var result = await this.checklistService.getProgressCategorys(this.activeUser.id).toPromise();
    this.countCheckLists = result;
  }

  async filterCheckList() {
    let data = {
      wedding_id: this.activeUser.id,
      status: 0,
      is_trash: null,
      due_now: null,
      after_wedding: null,
      catagory_id: null,
    };
    var result = await this.checklistService.filterCheckList(data).toPromise();
    this.allChecklist = result.data;
  }

  fileChangeEvent(event: any): void {
    // console.log('event1',event);
    // this.showCropper = true;
    this.showTham = false;
    this.imageChangedEvent = event;
    this.fileName = event.target.files[0].name;
    this.fileType = event.target.files[0].type;
    var size = event.target.files[0].size;
    if (size > 5300000) {
      this.showCropper = false;
      this.toastr.error('Please upload upto 5MB image');
    } else {
      if (this.fileType == 'image/jpeg' || this.fileType == 'image/jpg' || this.fileType == 'image/gif' || this.fileType == 'image/png') {
        this.showCropper = true;
      } else {
        this.showCropper = false;
        this.toastr.error('Please upload a jpeg, jpg, gif, png image');

      }
    }

  }
  imageCropped(event: ImageCroppedEvent) {
    this.showCropper = true;
    this.croppedImage = event.base64;
    // console.log('event', event);
    // console.log('image', this.croppedImage);
    // console.log(event, base64ToFile(event.base64));
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady(e: any) {
    // cropper ready
  }
  async removeCropper() {
    this.showCropper = false;
    this.showTham = true;
    var file = {
      name: this.fileName,
      type: this.fileType,
      base64: this.croppedImage
    };

    var data = { file: file, wedding_id: this.event_id };
    this.plannersService.saveProfilePhoto(data).subscribe((result: any) => {
      this.toastr.success(result.message);
      $("#photo").val('');
      $('#exampleModalCenter').modal('toggle');
      this.getWeddings();
      this.fileName = null;
      this.fileType = null;
      file = null;
      data = null;
      return false;
    },
      error => {
        this.toastr.error(error.message);
      })
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  modalClose() {

    // $('#exampleModalCenter').modal('toggle');


  }

  getRegionWiseCities(region_id: number) {
    this.locationService
      .getRegionWiseCities(region_id)
      .subscribe(
        (response: LocationModel[]) => {
          for (var i = 0; i < response.length; i++) {
            response[i]['text'] = response[i].name;
          }
          this.cities = response;
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }

  get f() { return this.plannerProfileForm.controls; }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.plannerProfileForm.invalid) {
      this.noteHide = true;
      return;
    }else{
      this.noteHide = false;
    }

    var aa = this.plannerProfileForm.value.first_couple_name;
    var bb = this.plannerProfileForm.value.first_couple_name;
    var date = this.usDateToDb($("#event_date").val());
    
    // var date = this.changeDateFormat(date);
    console.log(date);
    date = moment(date , "YYYY-MM-DD").format("YYYY-MM-DD");
    console.log(date);
    this.model.deserialize(this.plannerProfileForm.value);
    if ($("#event_date").val()) {
      // this.model.start_datetime = date + " " + this.plannerProfileForm.value.startHour + ":" + this.plannerProfileForm.value.startMinute + ":00";
      // this.model.end_datetime = date + " " + this.plannerProfileForm.value.endHour + ":" + this.plannerProfileForm.value.endMinute + ":00";
      this.model.start_datetime = date + " " + this.plannerProfileForm.value.startHour + ":" + this.plannerProfileForm.value.startMinute + ":00";
      this.model.end_datetime = date + " " + this.plannerProfileForm.value.endHour + ":" + this.plannerProfileForm.value.endMinute + ":00";
    }

    //this.model.state_id = Number(this.model.state_id);
    //this.model.city_id = Number(this.model.city_id);
    //console.log(this.model);return;
    // return
    this.plannersService
      .saveProfileData(this.model)
      .subscribe(
        (response: PlannerProfileModel) => {
          this.toastr.success(response.message);
          $('#exampleModalCenter').modal('toggle');
          this.first = aa;
          this.second = bb;
          this.getWeddings();
          // $('#exampleModalCenter').modal('toggle');
          // setTimeout(() => {
          //   $('#exampleModalCenter').modal('toggle');
          // }, 1000);
          // window.location.href =location.origin + "/planner-dashboard/";

          return false;
        },
        error => {          
          this.toastr.error(error.message);
        }
      )
      
  }

  usDateToDb(date: any) {
    var d = date.split("/"),
      month = d[1],
      day = d[0],
      year = d[2];

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  changeDateFormat(date: any) {
    var d = date.split("-"),
      day = d[1],
      year = d[0],
      month = d[2];

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  uploadImage(files: any) {
    var data = { file: files[0], wedding_id: this.event_id };
    this.plannersService.saveProfilePhoto(data).subscribe((result: any) => {
      this.toastr.success(result.message);
      $("#photo").val('');
      $('#exampleModalCenter').modal('toggle');
      this.getWeddings();
      this.showCropper = true;
      return false;
    },
      error => {
        this.showCropper = true;
        this.toastr.error(error.message);
      })
  }

  deleteProfilePhoto() {
    var photoId = this.event_id;
    this.plannersService.deleteProfilePhoto(photoId).subscribe((result: any) => {
      this.toastr.success(result.message);
      $('#exampleModalCenter').modal('toggle');
      this.getWeddings();
      return false;
    })
  }

  async myProfileData() {
    this.croppedImage = '';
    this.plannerProfileForm.reset();
    if (!this.weddings) return;
    var result = null;
    var weddings = this.weddings.filter((x: any) => x.is_active == 1);    
    if (weddings.length > 0) {
      result = weddings[0];
      this.plannersService.storeActiveUser(result);           
      if (result.first_couple_id && result.second_couple_id) {
        this.showLink = false;
      }
      this.profileData = result;      
      this.profileImage = Config.imageUrl + 'uploads/users/profile/' + this.profileData.file_name;
      this.event_id = result.id;
      var startHour = this.datetimeToTime(result.start_datetime);
      result.startHour = startHour.split(":")[0];
      var startMinute = this.datetimeToTime(result.start_datetime);
      result.startMinute = startMinute.split(":")[1];
      var endHour = this.datetimeToTime(result.end_datetime);
      result.endHour = endHour.split(":")[0];
      var endMinute = this.datetimeToTime(result.end_datetime);
      result.endMinute = endMinute.split(":")[1];
      result.event_date = new Date(this.datetimeToDate(result.start_datetime));
      this.first_couple_id = result.first_couple_id;
      this.second_couple_id = result.second_couple_id;
      this.first_couple_name = result.first_couple_name;
      this.second_couple_name = result.second_couple_name;
      this.wedding_website_url_name = result.wedding_website_url_name;
      this.first = result.first_couple_name;
      this.second = result.second_couple_name;
      $('#exampleModalCenter').on('hidden.bs.modal', () => {
        if (this.first == null && this.second == null) {
          $('#exampleModalCenter').modal('toggle');
        }
      })
      if (result.first_couple_type) {
        var first_couple_type = Config.weddingUserType.filter((x: any) => x.val == result.first_couple_type);
        if (first_couple_type.length > 0) {
          result.first_couple_type = first_couple_type[0].key;
        }
      }
      if (result.second_couple_type) {
        var second_couple_type = Config.weddingUserType.filter((x: any) => x.val == result.second_couple_type);
        if (second_couple_type.length > 0) {
          result.second_couple_type = second_couple_type[0].key;
        }
      }

      if (result && !result.first_couple_name || result && !result.second_couple_name) {
        setTimeout(() => {
          $('#exampleModalCenter').modal('toggle');
        }, 1000);
      }
      this.formBuilder.setValues(result);
      this.getRegionWiseCities(result.state_id);
      this.eventdate = new Date(result.start_datetime);
      clearInterval(this.funsettimeout);
      this.showCountDown();

    } else {
      $('#exampleModalCenter').modal('toggle');
    }


  }

  linkToAccount() {
    this.linksubmitted = true;
    this.linkEmail = this.AddLinkForm.value.linkEmail;
    if (this.AddLinkForm.invalid) {
      return;
    }
    if (this.linkEmail == null || this.linkEmail == "") {
      this.toastr.error("Email can not be blank");
      return false;
    }
    var data = { email: this.linkEmail, wedding_id: this.event_id, base_url: location.origin };
    this.plannersService.linkToProfile(data).subscribe((result: any) => {
      this.linksubmitted = false;
      this.toastr.success('Registration page to link accounts was generated');

      this.accountLink = location.origin + "/link-account/" + result.data;
      return false;
    })
  }

  linkPopUp() {
    $('#exampleModalCenter').modal('toggle');
    $('#basicExampleModal').modal('toggle');
  }

  datetimeToDate(datetime: any) {
    if (!datetime) return;
    var dateTime = datetime.split(" ");
    var exp = dateTime[0].split("-");
    var year = exp[0];
    var month = exp[1];
    var day = exp[2];
    return [month, day, year].join('/');
  }

  datetimeToTime(datetime: any) {
    if (!datetime) return;
    var dateTime = datetime.split(" ");
    var exp = dateTime[1].split(":");
    var hour = exp[0];
    var minutes = exp[1];
    return [hour, minutes].join(':');
  }

  getWeddings() {
    this.plannersService.getWeddings().subscribe((result: any) => {
      this.weddings = result;    
      this.plannersService.storeWeddings(this.weddings);      
      this.myProfileData();
    })
  }

  copyInputMessage() {
    if ($("#userinput").setSelectionRange) {
      $("#userinput").setSelectionRange(0, this.accountLink.length);
    } else {
      $("#userinput").select();

    }
    document.execCommand('copy');
    this.toastr.success('Link is copied to clipboard');
  }

  showCountDown() {
    if (!this.eventdate) return;
    clearInterval(this.funsettimeout);
    var now = new Date().getTime();
    var event_date = this.eventdate.getTime();
    if (event_date >= now) {
      clearInterval(this.funsettimeout);
      this.funsettimeout = setInterval(() => {
        var now = new Date().getTime();
        var distance = event_date - now;

        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        this.countdownText = days + " days " + hours + " hours "
          + minutes + " minutes " + seconds + " seconds <br>until our wedding day";

        if (distance < 0) {
          clearInterval(this.funsettimeout);
          this.countdownText = "EXPIRED";
        }
      }, 1000);
    }

  }

  goChecklist() {
    this.router.navigate(['checklist']);
  }

  goToConversations() {
    this.router.navigate(['wedding-community']);
  }

  checkEmail(e: any) {
    const EMAIL_REGEXP = /^[a-z0-9._]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  }
  getUrl(e: any) {
    this.wedding_website_url_name = e.target.value;   
  }

  getTaskCompletionPer(){
    let per = 0;
    if(this.hiredVendorData && this.hiredVendorData.total > 0){
      per = per + (this.hiredVendorData.hired / this.hiredVendorData.total);
    }
    if(this.countCheckLists && this.countCheckLists.total > 0){
      per = per + (this.countCheckLists.total_complet / this.countCheckLists.total);
    }
    if(this.apiGuestList && this.apiGuestList.length > 0){
      per = per + (this.attendGuest / this.totalGuest);
    }
    if(this.EventGuestList && this.EventGuestList.length > 0){
      per = per + (this.tableGuest / this.EventGuestList.length);
    }
    return Math.round(per * 100 / 4);
  }

  getPerImage(){
    let percentage = this.getTaskCompletionPer();
    if (percentage < 0 || percentage > 100) {
      throw new Error('Percentage must be between 1 and 100');
    }
    for (let i = 0; i < this.perImage.length; i++) {
      const range = this.perImage[i];
      if (percentage >= range.min && percentage <= range.max) {
        return range.image;
      }
    }

    return 'status-glass.png';
  }
}

