import { Component, OnInit } from '@angular/core';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { UsersService } from '../../users/services/users.service';
import { LoginFormBuilder } from '../../planners/login/login.form-builder';
import { LoginModel } from 'src/app/models/login.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PlannersService } from '../services/planners.service';
// import { AuthService as SocialAuth, FacebookLoginProvider} from 'angularx-social-login';
// import { GoogleLoginProvider } from 'angularx-social-login';

import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import {  Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  submitted = false;
  model = new LoginModel();
  loginForm: any;
  isLoader = false;
  returnUrl: string;
  googleUser: any;

  constructor(private plannersService: PlannersService,
    private formBuilder: LoginFormBuilder,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    public router: Router,
    private authService: SocialAuthService) {
  }

  get f() { return this.loginForm.controls; }

  ngOnInit() {
    this.loginForm = this.formBuilder.getForm();
    this.resetForm();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/planner-dashboard';
    console.log(this.returnUrl);
    window.scroll(0, 0);
  }

  getUserRole(authResponse: any){
    return authResponse.claims.roles[0].name;
  }

  onSubmit(type:number) {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.model.deserialize(this.loginForm.value);
    // console.log(this.loginForm.value);
    // return;
    this.isLoader = true;
    this.plannersService
      .authenticate(this.model)
      .subscribe(
        (response: AuthResponseModel) => {
          if (response.token) {
            let user_role = this.getUserRole(response);
            if(user_role === "Wedding Users"){
              this.storeActiveUserData();
              this.toastr.success("Login Success");
              return false;
            }else if(user_role === "Super Admin"){
              this.router.navigate(["/"], { replaceUrl: true });
            }
          }
          else {
            this.isLoader = false;
            this.toastr.error("Login failure");
          }
        },
        error => {
          this.isLoader = false;
          
          this. resetForm();
          if(type==0){
            this.toastr.error(error.message);
          }else{ 
            this.plannersService.storeSocialData(type);    
            this.loginForm.controls['password'].clearValidators();
            this.loginForm.controls['password'].setValidators([Validators.required,Validators.minLength(6)]);
            this.loginForm.controls['email'].clearValidators();
            this.loginForm.controls['email'].setValidators([Validators.required,Validators.email]);
            this.router.navigate(['/register']);
            this.toastr.error('This user not registered, please registered first');
          }
        }
      )
  }

  resetForm() {
    this.submitted = false;
    this.loginForm.reset();
  }

  googleSignIn() {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((user) => {
      this.googleUser = user;
      if (user.authToken) {
        const credentials = {
          name: user.name,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          provider_id: user.id,
          provider_name: user.provider
        };
        this.setSigninValues(credentials, 1);
      }
    }, (err) => {
      console.log('error', err);
    });
  }

  fbSignIn() {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then((user) => {
      this.googleUser = user;
      if (user.authToken) {
        const credentials = {
          name: user.name,
          firstName: user.firstName,
          lastName: user.lastName,
          email: (user.email === undefined) ? '' : user.email,
          provider_id: user.id,
          provider_uid: user.id,
          provider_name: user.provider
        };
        this.setSigninValues(credentials, 2);
      }
    }, (err) => {
      console.log('fberror', err);
    });
  }

  setSigninValues(credentials: any, type: any) {
    this.loginForm.get('email').setValue(credentials.email);
    this.loginForm.controls['password'].clearValidators();
    this.loginForm.controls['password'].setErrors(null);
    this.loginForm.controls['password'].setValidators(null);

    this.loginForm.controls['email'].clearValidators();
    this.loginForm.controls['email'].setErrors(null);
    this.loginForm.controls['email'].setValidators(null);


    this.loginForm.get('password').setValue(null);
    if (type == 1) {
      this.loginForm.get('google_provider_id').setValue(credentials.provider_id);
    } else {
      this.loginForm.get('fb_provider_id').setValue(credentials.provider_id);
    }
    this.onSubmit(credentials);

  }

  storeActiveUserData(){
    this.plannersService.getWeddings().subscribe((result: any) => {
      var weddings = result;    
      this.plannersService.storeWeddings(weddings);      
      var filteredweddings = weddings.filter((x: any) => x.is_active == 1); 
      var wedding = filteredweddings[0];
      this.plannersService.storeActiveUser(wedding); 
      this.router.navigate([this.returnUrl], { replaceUrl: true });
    });
  }
}
