import { Component, OnInit } from '@angular/core';
import { DiscussionService } from '../services/discussions.service';
import { PlannersService } from '../../planners/services/planners.service';
import { Router } from '@angular/router';
import { Config } from '../../../config';
import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-wedding-forum',
  templateUrl: './wedding-forum.component.html',
  styleUrls: ['./wedding-forum.component.scss']
})
export class WeddingForumComponent implements OnInit {
  discussionCategories: any;
  vendorCategoryId: any;
  VendorCategoryName: any;
  activeUser: any;
  search_text: string = "";
  search_dropdown_visible: boolean = false;
  search_result: any[] = [];
  forums_discussions: any[] = [];
  current_page_number: number = 1;
  total_page_number: number = 1;
  total_discussions: number = 0;
  category_banner_image_url: string = Config.imageUrl + 'images/discussion_category_banner/';

  constructor(
    private discussionService: DiscussionService,
    private plannersService: PlannersService,
    public router: Router
  ) {
    this.plannersService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
      } else {
        this.activeUser = null;
      }
    }, (err) => {
        console.error(err);
    });
  }

  async ngOnInit() {
    this.getDiscussionList(1);
    await this.getDiscussionCategories();
    var owl = $('.owl-carousel');
    setTimeout(() => {
      owl.owlCarousel({
        margin: 30,
        nav: true,
        loop:true,
        autoplay:false,
        autoplayTimeout:3000,
        autoplayHoverPause:true,
        smartSpeed: 400,
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 4
          },
          1000: {
            items: 4
          }
        }
      })
    }, 1000);   
  }
  async getDiscussionList(page_number: number){
    let formData = {
      "category_id": "",
      "page_number": page_number
    };
    this.current_page_number = page_number;
    var response = await this.discussionService.getDiscussionList(formData).toPromise();
    this.forums_discussions = response.data;
    this.total_page_number = Math.ceil(response.total_records / 10);
    this.total_discussions = response.total_records;
  }
  async getDiscussionCategories() {
    var vendorCategory = await this.discussionService.getDiscussionCategories().toPromise();
    this.discussionCategories = vendorCategory;
  }
  async goToTopic(topicName: any) {
    this.router.navigate(['/community/' + topicName]);
  }
  async goToConvo(slug: string){
    this.router.navigate(['/conversation/' + slug]);
  }
  async startNewConvo() {
    this.router.navigate(['/wedding-community/']);
  }
  changeCategory(name){
    var category = this.discussionCategories.filter((e: any) => e.name == name);
    this.VendorCategoryName = category[0].name;
    this.vendorCategoryId = category[0].id;
    this.router.navigate(['/community/' + this.VendorCategoryName]);
  }
  async search_topic(){
    if(this.search_text.length < 3){
      return;
    }
    let formData = {
      "search_text": this.search_text
    };
    var response = await this.discussionService.searchDiscussion(formData).toPromise();
    if(response.hasError === false){
      this.search_result = response.data;
    }
  }
  openDropdown(){
    this.search_dropdown_visible = true;
  }
  hideDropdown(){
    setTimeout(()=>{
      this.search_dropdown_visible = false;
    }, 300);
  }
  columns(){
    return [
      this.discussionCategories.slice(0, Math.ceil(this.discussionCategories.length / 3)),
      this.discussionCategories.slice(Math.ceil(this.discussionCategories.length / 3), 2 * Math.ceil(this.discussionCategories.length / 3)),
      this.discussionCategories.slice(2 * Math.ceil(this.discussionCategories.length / 3))
    ];  
  }
  relativeTime(created_time: string){
    var now = moment();
    var date = moment(created_time);
    if (now.diff(date, 'days') < 1) {
        return date.fromNow();  // e.g., "8 hours ago"
    } else if (now.diff(date, 'days') === 1) {
        return 'Yesterday at ' + date.format('h:mm A');
    } else {
        return date.format('MMM D, YYYY [at] h:mm A');
    }
  }

  getPages(){
    return Array.from({ length: this.total_page_number }, (_, i) => i + 1);
  }
}
