import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { error } from 'protractor';
import { Router, RouterStateSnapshot } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

declare var $: any;

@Injectable()
export class WebServiceInterceptor implements HttpInterceptor{
    
    constructor(
        public router: Router,
        private toastr:ToastrService){};

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
      ): Observable<HttpEvent<any>> {        
        
        $(".overlayDiv").show();
        return next.handle(req).pipe(
            catchError(err => {
                //console.log(1122,err.status);           
                if(err.status == 403){
                    localStorage.removeItem("USER_TOKEN");
                    localStorage.removeItem("fname");
                    localStorage.removeItem("UID");
                    var role = localStorage.getItem("role");
                    if(role=='Wedding Users'){
                        this.router.navigate(['login'],{ queryParams: { returnUrl: this.router.url }});
                    }else{
                        this.router.navigate(['vendor/login'],{ queryParams: { returnUrl: this.router.url }});
                    }                    
                }else{
                    //console.log(err);
                    this.toastr.error("Somthing is wrong. Please check network connection and try again.");
                }
                return of(null);
            }),
            finalize(() => {
                $(".overlayDiv").hide();
            })            
        );    
      }
}
