import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { catchError } from 'rxjs/operators';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { UsersService } from '../../users/services/users.service';
import { Config } from '../../../config';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { PlaceholdersService } from '../../cms/services/placeholders.service';
import { VendorsService } from '../services/vendors.service';
import { RequestPriceModel } from 'src/app/models/request.price.model';
import { AgmCoreModule, MapsAPILoader, GoogleMapsAPIWrapper, AgmMap } from '@agm/core';
import { PlannersService } from '../../planners/services/planners.service';
import { VendorManagerService } from '../../planners/services/vendor-manager.service';

declare var $: any;
declare var google: any;
@Component({
  selector: 'app-vendor-details',
  templateUrl: './vendor-details.component.html',
  styleUrls: ['./vendor-details.component.css', './vendor-details.component.scss']
})
export class VendorDetailsComponent implements OnInit {

  geocoder: any;
  vendorDetailInfo: any;
  vendorinfo: any;
  args: any;
  showphone = false;
  listingView: number;
  mapView: number;
  lat: number;
  long: number;
  zoom: number;
  iconUrl: string;
  currentUrl: any;
  getUrl: any;
  baseUrl = Config.baseUrl;
  venueSlider: any = null;
  reffrer: any;
  vendor_id: number;
  sliderMode: string = 'photo';

  priceCurrentPage: number = 1;
  dealCurrentPage: number = 1;
  preferredVendorCurrentPage: number = 1;
  faqCurrentPage: number = 1;
  availablityCurrentPage: number = 1;
  pagination_per_Page: number = Config.pagination_per_Page;

  showAvilibilityCalendar: boolean = false;
  pop_photo: boolean = true;
  pop_video: boolean = false;

  @ViewChild(AgmMap) map: AgmMap;
  @ViewChild('checkAvailability') checkAvailability: any;
  categoryId: any;
  activeUser: any;
  rating: number = 0;
  ratingSummary: any;
  ratingCommunication: any;
  ratingValue: any;
  ratingQualityService: any;
  ratingProfessionalism: any;
  ratingOverall: any;

  reviews: any[] = [];
  ratings: number[] = [1, 2, 3, 4, 5];
  current_page: number = 1;
  pagination_items: any[] = [];
  total_reviews: number = 0;
  image_url: string = Config.imageUrl;

  lightBoxCurrentImageIndex: number = 0;
  lightBoxImages: any[] = [];
  lightBoxImage: any;
  slider_images: any[] = [];

  selected_reply: any;
  selected_review: any;
  update_reply_message: string;
  hideSavedButton:boolean = true;
  showSavedButton:boolean = false;
  constructor(private usersService: UsersService,
    private vendorService: VendorsService,
    private toastr: ToastrService,
    private mapsAPILoader: MapsAPILoader,
    private route: ActivatedRoute,
    private _location: Location,
    private placeholdersService: PlaceholdersService,
    private VendorManagerService: VendorManagerService,private plannersService: PlannersService,
    public router: Router) {
    //this.loginForm = formBuilder.getForm();
    this.mapsAPILoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
    });
    this.plannersService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
      } else {
        this.activeUser = null;
      }
    },
      (err) => {
        console.error(err);
      });
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.vendorDetailInfo = { bookings: [], customefaq: [], deals: [], docs: [], faqfiltername: {}, faqs: [], featureimage: [], min_price: [], photos: [], preferredvendor: [], pricing: [], similarvendor: [], sociallink: [], vendor: {}, videos: [] };
    this.lat = Config.globalLat;
    this.long = Config.globalLong;
    this.zoom = 16;
    this.iconUrl = "http://maps.google.com/mapfiles/ms/icons/blue-dot.png";
    this.currentUrl = this.router.url.split("#");
    this.currentUrl = this.baseUrl + this.currentUrl[0];
    this.showphone = false;
    this.route.params.subscribe(params => {
      this.args = params;
      this.slugToVendorId(params);
    })

    this.reffrer = document.referrer;
  }
  closeSlidePopup() {
    $('#slider').owlCarousel('destroy');
    $('#thumbnailSlider').owlCarousel('destroy');
    $(".slider_pop").hide();
  }
  vendorDetails(id: number) {
    var owl_wedding_photo = $('.wedding-photos');
    let vendorInfoFormData = {
      vendor_id: id,
      wedding_id: this.activeUser ? this.activeUser.id : undefined
    };
    this.vendorService
      .getVendorInfo(vendorInfoFormData).subscribe((response) => {
          this.vendorDetailInfo = response;
          if(this.vendorDetailInfo && this.vendorDetailInfo.avg_rating){
            this.rating = this.vendorDetailInfo.avg_rating;
          }
          if(this.vendorDetailInfo && this.vendorDetailInfo.rating_summary){
            let ratingSummary = this.vendorDetailInfo.rating_summary;
            this.ratingOverall = ratingSummary.overall.toFixed(1);
            this.ratingValue =  ratingSummary.value.toFixed(1);
            this.ratingQualityService =  ratingSummary.quality_service.toFixed(1);
            this.ratingProfessionalism =  ratingSummary.professionalism.toFixed(1);
            this.ratingCommunication = ratingSummary.communication.toFixed(1);
          }
          if(this.vendorDetailInfo.photos && this.vendorDetailInfo.photos.length){
            this.slider_images = this.vendorDetailInfo.photos.map((photo: any)=>{
              return {
                'image_file_name': photo.file_name
              };
            });
          }
          
          this.vendorDetailInfo.vendor.business_phone = this.formatPhoneNumber(this.vendorDetailInfo.vendor.business_phone)
          this.categoryId = this.vendorDetailInfo.vendor['category_id'];
          for (var i = 0; i < response.pricing.length; i++) {
            var sson = "";
            var dow = "";
            for (var k = 0; k < response.pricing[i].season.length; k++) {
              sson += this.search(response.pricing[i].season[k], Config.seasonArray);
            }
            response.pricing[i].season = sson.slice(0, -1);
            for (var k = 0; k < response.pricing[i].days_of_week.length; k++) {
              dow += this.search(response.pricing[i].days_of_week[k], Config.daysArray);
            }
            response.pricing[i].days_of_week = dow.slice(0, -1);
          }

          for (var m = 0; m < response.faqs.length; m++) {
            if (response.faqs[m].question_type == 'checkbox') {
              var split = response.faqs[m].answer.split(",");
              response.faqs[m].answer = split.join(", ");
            }
          }

          setTimeout(() => {
            owl_wedding_photo.owlCarousel({
              stagePadding: 100,
              items: 1,
              margin: 2,
              nav: true,
              loop: true,
              autoplay: true,
              autoplayTimeout: 3000,
              autoplayHoverPause: true,
              smartSpeed: 400,
              URLhashListener: true,
              startPosition: 0,
              //autoWidth: true,
              responsiveClass: true,
              responsive: {
                0: {
                  items: 1,
                  stagePadding: 0
                },
                600: {
                  items: 1
                },
                1000: {
                  items: 1
                }
              }
            });            
            this.showPopSlider();
          }, 1000);

          setTimeout(function () {
            var owl = $('.owl-carousel');
            owl.owlCarousel({
              margin: 30,
              nav: true,
              loop: false,
              autoplay: false,
              autoplayTimeout: 3000,
              autoplayHoverPause: true,
              smartSpeed: 400,
              responsive: {
                0: {
                  items: 1
                },
                600: {
                  items: 3
                },
                1000: {
                  items: 3
                }
              }
            })
          }, 1000)

          //console.log(this.vendorDetailInfo);
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }
  formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }

  showMedia(arg: string) {
    this.sliderMode = arg;
  }

  backClicked() {
    this._location.back();
  }

  updateHireStatusOfVendor(hiredStatus: number) {
    if(this.activeUser){
      let formData = {
        wedding_id: this.activeUser.id,
        vendor_id: this.vendorDetailInfo.vendor.vendor_id,
        toStatus: hiredStatus
      };
      this.VendorManagerService.vendorUpdateStatus(formData).subscribe((result: any) => {
        this.toastr.success(result.data.message);
        if(hiredStatus == 2){
        this.vendorDetailInfo.hired = true;
        this.vendorDetailInfo.saved = true;
        }
        else if(hiredStatus ==  -1){
        this.vendorDetailInfo.hired = false;
        this.vendorDetailInfo.saved = false;
        }else{
          this.vendorDetailInfo.hired = false;
          this.vendorDetailInfo.saved = true;
        }
      }, error => {
        this.toastr.error(error.message);
      })
    }else{
      this.router.navigate(["login"], {queryParams: {returnUrl: location.pathname}});
    }
  }

  goToVendorCategory(){
    this.router.navigate(['/vendor-category', this.categoryId]);
  }

  viewPhone() {
    if (this.showphone == false) {
      this.showphone = true;
      var arg = { "mode": "mobile", "vendor_id": this.vendor_id };
      this.viewCount(arg);
    } else {
      this.showphone = false;
    }

  }

  redirectVendor(id: number) {
    this.router.navigate(["profile/" + id]);
  }

  addSubject(arg: string) {
    var subject = "Message from ";
    if (arg == 'pricing') {
      subject += "pricing";
    } else if (arg == 'deal') {
      subject += "deal";
    } else if (arg == 'availability') {
      subject += "availability";
    } else {
      subject += "profile";
    }

    $("#subject").val(subject);
    $(".vendor_message").addClass("divbackgroundcolor");
    $(".close_div").show();
    $("#name").focus();
  }
  search(nameKey, myArray) {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].id === nameKey) {
        return myArray[i].name.slice(0, 2) + ",";
      }
    }
  }

  viewCount(args: any) {
    this.vendorService
      .viewCount(args)
      .subscribe(
        (response) => {

        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }

  viewWebsite() {
    var arg = { "mode": "website", "vendor_id": this.vendor_id };
    this.viewCount(arg);
  }

  slugToVendorId(arg: any) {
    //console.log(arg);
    this.vendorService
      .slugToVendorId(arg)
      .subscribe(
        (response) => {
          if (response.data == 0) {
            this.toastr.error("Invalid profile link.");
            this.router.navigate(['/']);
            return;
          }
          this.vendor_id = response.data;          
          $("#vendor_id").val(response.data);
          this.vendorDetails(response.data);
          var arg = { "mode": "detail", "vendor_id": response.data };
          this.viewCount(arg);
          this.getAllReviews();
          this.checkAvailability.vendorId = this.vendor_id;
          this.checkAvailability.loadInit();
        },
        error => {
          this.toastr.error(error.message);
          //
        }
      )
  }

  openAvilibilityCalendar() {
    this.showAvilibilityCalendar = true;
  }

  onCloseAvilibilityCalendar(state: boolean) {
    this.showAvilibilityCalendar = false;
  }


  showPopSlider() {

    $('.wedding-photos .item').click((e, index) => {
      var ele = e.target;
      var parentEle = $(ele).parent();
      var id = $(parentEle).attr("id");
      var getTypeClass = $(ele).parent().parent().parent().parent().parent();
      if ($(getTypeClass).hasClass("wedding-videos")) {
        this.pop_video = true;
        this.pop_photo = false;
      } else {
        this.pop_photo = true;
        this.pop_video = false;
      }
      //$(".slider_video_pop").show();
      setTimeout(() => {
        // reference for main items
        var slider = $('#slider');
        // reference for thumbnail items
        var thumbnailSlider = $('#thumbnailSlider');
        //transition time in ms
        var duration = 250;

        // carousel function for main slider
        slider.owlCarousel({
          loop: false,
          nav: false,
          items: 1
        }).on('changed.owl.carousel', function (e) {
          //On change of main item to trigger thumbnail item
          thumbnailSlider.trigger('to.owl.carousel', [e.item.index, duration, true]);
        });

        // carousel function for thumbnail slider
        thumbnailSlider.owlCarousel({
          loop: false,
          center: true, //to display the thumbnail item in center
          nav: false,
          responsive: {
            0: {
              items: 3
            },
            600: {
              items: 4
            },
            1000: {
              items: 6
            }
          }
        }).on('click', '.owl-item', function () {
          // On click of thumbnail items to trigger same main item
          slider.trigger('to.owl.carousel', [$(this).index(), duration, true]);

        }).on('changed.owl.carousel', function (e) {
          // On change of thumbnail item to trigger main item
          slider.trigger('to.owl.carousel', [e.item.index, duration, true]);
        });

        //These two are navigation for main items
        $('.slider-right').click(function () {
          slider.trigger('next.owl.carousel');
        });
        $('.slider-left').click(function () {
          slider.trigger('prev.owl.carousel');
        });
        slider.trigger('to.owl.carousel', [id, duration, true]);
        $(".slider_pop").show();
        this.selectPopUp('photo');
      }, 1000);
    });
  }

  selectPopUp(arg: string) {
    $('#slider').owlCarousel('destroy');
    $('#thumbnailSlider').owlCarousel('destroy');
    setTimeout(() => {
      // reference for main items
      var slider = $('#slider');
      // reference for thumbnail items
      var thumbnailSlider = $('#thumbnailSlider');
      //transition time in ms
      var duration = 250;

      // carousel function for main slider
      slider.owlCarousel({
        loop: false,
        nav: false,
        items: 1
      })

      // carousel function for thumbnail slider
      thumbnailSlider.owlCarousel({
        loop: false,
        center: true, //to display the thumbnail item in center
        nav: false,
        responsive: {
          0: {
            items: 3
          },
          600: {
            items: 4
          },
          1000: {
            items: 6
          }
        }
      })

      //These two are navigation for main items
      $('.slider-right').click(function () {
        slider.trigger('next.owl.carousel');
      });
      $('.slider-left').click(function () {
        slider.trigger('prev.owl.carousel');
      });

    }, 1000);

    if (arg == 'video') {
      this.pop_video = true;
      this.pop_photo = false;
    } else {
      this.pop_video = false;
      this.pop_photo = true;
    }
  }

  getAllReviews(){
    var formData = {
      "vendor_id": this.vendor_id,
      "page_number": this.current_page
    };
    this.VendorManagerService.reviewList(formData).subscribe(res => {
      this.reviews = res.data;
      this.total_reviews = res.total_reviews;
      this.pagination_items = this.pagination(this.current_page, Math.ceil(res.total_reviews / 10));
      if (res.my_review != null) {
        res.my_review.user_details.lastname = res.my_review.user_details.lastname + " (You)";
        res.my_review.is_my_review = true;
        this.reviews.unshift(res.my_review);
      }
    }, error => {
      console.log(error);
    });
  }

  getReviewTime(date: string){
    return new Date(date).toDateString();
  }

  goPrev() {
    this.current_page = this.current_page - 1;
    this.getAllReviews();
  }
  goNext() {
    this.current_page = this.current_page + 1;
    this.getAllReviews();
  }
  goToPage(pageItem: any) {
    this.current_page = Number(pageItem);
    this.getAllReviews();
  }
  pagination(current: number, last: number, delta = 2) {
    if (last === 1) return ["*" + 1 + "*"];

    const left = current - delta,
      right = current + delta + 1,
      range = [];

    if (last > 1 && current !== 1) {
      range.push("<");
    }

    for (let i = 1; i <= last; i++) {
      if (i == 1 || i == last || (i >= left && i < right)) {
        if (i === left && i > 2) {
          range.push("...");
        }

        if (i === current) {
          range.push("*" + i + "*");
        } else {
          range.push(i);
        }

        if (i === right - 1 && i < last - 1) {
          range.push("...");
        }
      }
    }

    if (last > 1 && current !== last) {
      range.push(">");
    }

    return range;
  }
  getPageItemText(pageItem: any) {
    if (isNaN(pageItem)) {
      if (pageItem.indexOf('*') === 0) {
        return pageItem.substr(1, (pageItem.length - 2));
      }
    } else {
      return pageItem;
    }
  }
  isNumber(value: any) {
    return !isNaN(value);
  }
  openReviewLighbox(imgs: any[], index: number){
    this.lightBoxCurrentImageIndex = index;    
    for (let index = 0; index < imgs.length; index++) {
      this.lightBoxImage =  imgs[index].image_name;
      this.lightBoxImages.push(this.lightBoxImage);
    }
    this.lightBoxImage = this.lightBoxImages[index];
  }
  storyPlusSlides() {
    if(this.lightBoxCurrentImageIndex < this.lightBoxImages.length - 1){
      this.lightBoxCurrentImageIndex = this.lightBoxCurrentImageIndex + 1
      this.lightBoxImage = this.lightBoxImages[this.lightBoxCurrentImageIndex];
    }
  }
  storyMinussSlides() { 
    if(this.lightBoxCurrentImageIndex > 0){
      this.lightBoxCurrentImageIndex = this.lightBoxCurrentImageIndex - 1
      this.lightBoxImage = this.lightBoxImages[this.lightBoxCurrentImageIndex];
    }
  }
  getNow(){
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    return ( year + "-" + ((month > 9) ? '' + month : '0' + month) + "-" + ((day > 9) ? '' + day : '0' + day)) + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  }
  getRelateiveTime(datetime: string) {
    var msPerMinute: number = 60 * 1000;
    var msPerHour: number = msPerMinute * 60;
    var msPerDay: number = msPerHour * 24;
    var msPerMonth: number = msPerDay * 30;
    var msPerYear: number = msPerDay * 365;
    var currentDate: number = Date.parse(this.getNow());
    var previousDate: number  = Date.parse(datetime);
    var elapsed: number = currentDate - previousDate;
    if (elapsed < msPerMinute) {
        return Math.round(elapsed/1000) + ' seconds ago';   
    }else if (elapsed < msPerHour) {
        return Math.round(elapsed/msPerMinute) + ' minutes ago';   
    }else if (elapsed < msPerDay ) {
        return Math.round(elapsed/msPerHour ) + ' hours ago';   
    }else if (elapsed < msPerMonth) {
        return Math.round(elapsed/msPerDay) + ' days ago';   
    }else if (elapsed < msPerYear) {
        return Math.round(elapsed/msPerMonth) + ' months ago';   
    }else {
        return Math.round(elapsed/msPerYear ) + ' years ago';   
    }
  }
  sendReply(reviewData: any){
    var data={
      review_id: reviewData.id,
      reply_message: reviewData.replyMessage
    };
    this.vendorService.submitReplyToReviewByUser(data).subscribe((response:any) => {
      let resReview = response;
      this.reviews = this.reviews.map((eachReview)=>{
        if(eachReview.id === resReview.id){
          return resReview;
        }else{
          return eachReview;
        }
      });
    }, error => {
      console.log(error.message);
    });
  }
  deleteReply(reply: any, review: any){
    this.selected_review = review;
    this.selected_reply = reply;
    $('#confirmDeleteReplyModal').modal('show');
  }
  confirmDeleteReply(){
    let formData = {
      review_id: this.selected_review.id,
      reply_id: this.selected_reply.id
    };
    this.vendorService.deleteReviewReplyByUser(formData).subscribe((response:any) => {
      let resReview = response;
      this.reviews = this.reviews.map((eachReview)=>{
        if(eachReview.id === resReview.id){
          return resReview;
        }else{
          return eachReview;
        }
      });
    }, error => {
      console.log(error.message);
    });
  }
  EditReply(reply: any, review: any){
    this.selected_review = review;
    this.selected_reply = reply;
    this.update_reply_message = this.selected_reply.message;
    $('#confirmEditReplyModal').modal('show');
  }
  updateReply(){
    let formData = {
      review_id: this.selected_review.id,
      reply_id: this.selected_reply.id,
      message: this.update_reply_message
    };
    this.vendorService.updateReviewReplyByUser(formData).subscribe((response:any) => {
      let resReview = response;
      this.reviews = this.reviews.map((eachReview)=>{
        if(eachReview.id === resReview.id){
          return resReview;
        }else{
          return eachReview;
        }
      });
    }, error => {
      console.log(error.message);
    });
  }
  parseInt(value: number){
    return Number.parseInt(value + "");
  }
  goToEditYourReview(){
    this.router.navigate(['/vendor-ratings', this.vendor_id]);
  }
}
